<template>
  <div class='left-menu'>
    <!-- <div class='title'>{{title}}</div> -->
    <div class='menu-tree-box' v-if='data.length'>
      <div class='item flex-a-c' :class="{active: current===item.key}" @click='changeTab(item.key)' v-for='item in data' :key='item.key'>
        <icon-svg class="icon" icon-class="left-menu-icon" />
        <div>{{item.title}}</div>
      </div>
    </div>
    <div v-else style='margin-top: 64px'>
      <a-empty></a-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: 'leftMenu',
  props: {
    title: String,
    data: Array,
    activeKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      current: '',
    }
  },
  watch: {
    activeKey: {
      handler(val) {
        this.current = val ? val : this.data.length ? this.data[0].key : ''
      },
      immediate: true,
    },
  },
  mounted() {
    this.current = this.activeKey ? this.activeKey : this.data.length ? this.data[0].key : ''
  },
  methods: {
    changeTab(key) {
      if (key === this.current) return
      this.$emit('change', key)
      this.current = key
    },
  },
}
</script>

<style lang="less" scoped>
.left-menu {
  flex-shrink: 0;
  // width: 200px;
  min-width: 200px;
  height: 400px;
  background: #ffffff;
  box-shadow: 0 4px 8px #ddd;
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 16px;
  .title {
    line-height: 52px;
    background: #2b2b2b;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .menu-tree-box {
    // padding: 16px 0;
    .item {
      line-height: 52px;
      padding: 0 32px;
      font-size: 14px;
      color: #000;
      position: relative;
      cursor: pointer;
      &.active {
        color: #fff;
        font-weight: bold;
        background: #38b352;
        margin-left: -16px;
        padding-left: 48px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        .icon {
          color: #fff;
        }
      }
    }
  }
}
.icon {
  font-size: 16px;
  font-weight: 900;
  margin-right: 4px;
  margin-top: 2px;
  // color: #38b352;
}
</style>