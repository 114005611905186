<template>
  <div class='nav' id='nav'>
    <div class='page-content flex-a-c flex-j-b'>
      <router-link to='/' tag='div' class='logo-img-box'>
          <img class='logo-img' src='@/assets/logo.png'>
        </router-link>
      <div class='nav-list flex-a-c'>
        <router-link to='/' tag="div" class='nav-item' :class='{active: $route.path === "/"}'>
          <span>首页</span>
        </router-link>
        <router-link tag="div" to='/company_intro' class='nav-item'>
          <span>走进青欣</span>
          <div class='second-nav-list'>
            <router-link  tag="div" :class='{active: $route.path === "/company_intro"}' to='/company_intro' class='second-nav-item'>
              集团介绍
            </router-link>
            <router-link  tag="div" :class='{active: $route.path === "/company_culture"}' to='/company_culture' class='second-nav-item'>
              集团文化
            </router-link>
            <router-link  tag="div" :class='{active: $route.path === "/company_honor"}' to='/company_honor' class='second-nav-item'>
              公司荣誉
            </router-link>
            <router-link  tag="div" :class='{active: $route.path === "/company_qualification"}' to='/company_qualification' class='second-nav-item'>
              资质证书
            </router-link>
          </div>
        </router-link>
        <router-link tag='div' to='/news' class='nav-item' :class='{active: $route.path === "/news"}'>
          <span>新闻资讯</span>
        </router-link>
        <router-link tag="div" to='/project_case' class='nav-item' :class='{active: $route.path === "/project_case"}'>
          <span>工程案例</span>
          <div class='second-nav-list'>
            <router-link tag="div" :class='{active: $route.path === "/project_case/0" ||$route.path === "/project_case"}' to='/project_case/0' class='second-nav-item'>
              市政工程
            </router-link>
            <router-link  tag="div" :class='{active: $route.path === "/project_case/1"}' to='/project_case/1' class='second-nav-item'>
              公路工程
            </router-link>
            <router-link  tag="div" :class='{active: $route.path === "/project_case/2"}' to='/project_case/2' class='second-nav-item'>
              小区景观
            </router-link>
            <router-link  tag="div" :class='{active: $route.path === "/project_case/3"}' to='/project_case/3' class='second-nav-item'>
              校园绿化
            </router-link>
          </div>
        </router-link>
        <router-link tag='div' to='/industry' class='nav-item' :class='{active: $route.path === "/industry"}'>
          <span>行业领域</span>
          <div class='second-nav-list' style='width: 160px;left: -30px;'>
            <router-link tag='div' :class='{active: $route.path === "/industry/0"||$route.path ==="/industry"}' to='/industry/0' class='second-nav-item'>
              市政园林工程施工
            </router-link>
            <router-link tag='div' :class='{active: $route.path === "/industry/1"}' to='/industry/1' class='second-nav-item'>
              农业苗木
            </router-link>
            <router-link tag='div' :class='{active: $route.path === "/industry/2"}' to='/industry/2' class='second-nav-item'>
              铭喆酒店
            </router-link>
            <router-link tag='div' :class='{active: $route.path === "/industry/3"}' to='/industry/3' class='second-nav-item'>
              生态旅游
            </router-link>
            <router-link tag='div' :class='{active: $route.path === "/industry/4"}' to='/industry/4' class='second-nav-item'>
              职业培训学校
            </router-link>
            <router-link tag='div' :class='{active: $route.path === "/industry/5"}' to='/industry/5' class='second-nav-item'>
              立体花卉
            </router-link>
          </div>
        </router-link>
        <router-link tag='div' to='/about_us' class='nav-item' :class='{active: $route.path === "/about_us"}'>
          <span>联系我们</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
}
</script>
<style lang="less" scoped>
.nav {
  width: 100%;
  height: 88px;
  box-shadow: 0 2px 4px #ddd;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #fff;
  margin-bottom: 1px;
  .page-content {
    height: 100%;
    .logo-img-box {
      height: 64px;
      min-width: 300px;
      cursor: pointer;
      .logo-img {
        height: 64px;
        object-fit: contain;
      }
    }

    .nav-list {
      height: 100%;
      .nav-item {
        line-height: 88px;
        color: #333;
        font-size: 16px;
        width: 100px;
        text-align: center;
        cursor: pointer;
        position: relative;
        &.active span {
          color: #38b352;
        }
        &:hover span {
          color: #38b352;
        }
        .second-nav-list {
          position: absolute;
          top: 88px;
          left: -10px;
          background: #fff;
          width: 120px;
          transition: 0.3s;
          height: 0;
          overflow: hidden;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          z-index: 1;
          .second-nav-item {
            text-align: center;
            line-height: 48px;
            &:hover {
              color: #38b352;
            }
            &.active {
              color: #38b352;
            }
          }
        }
      }
      .nav-item:nth-of-type(2):hover .second-nav-list {
        height: 200px;
        padding-bottom: 8px;
      }
      .nav-item:nth-of-type(4):hover .second-nav-list {
        height: 200px;
        padding-bottom: 8px;
      }
      .nav-item:nth-of-type(5):hover .second-nav-list {
        height: 296px;
        padding-bottom: 8px;
      }
    }
  }
}
</style>