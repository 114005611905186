<template>
  <div class="footer-box">
    <div class="flex-a-c">
      <a target="_blank" class="flex-a-c" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37070202000344">
        <img src="@/assets/beian.png" alt="" style="margin-right: 4px" />
        <p>鲁公网安备 37070202000344号</p>
      </a>
      <a style="margin-left: 24px" target="_blank" href="http://beian.miit.gov.cn">
        <p>鲁ICP备2021008287号-1</p>
      </a>
    </div>
    <div style="margin-top: 4px">
      <p>
        Copyright &copy; 2021 All Rights Reserved 青欣建筑集团有限公司版权所有
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.footer-box {
  height: 80px;
  background-color: #2b2b2b;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 64px;
  box-sizing: border-box;
  font-size: 14px;
}
.footer-box p {
  margin: 0;
}
.footer-box a {
  color: #fff;
}
.footer-box a:hover {
  color: #38b352;
}
</style>