<template>
  <div class='body'>
    <Nav></Nav>
   <div class="banner" style="margin-bottom:12px">
        <swiper ref="bannerSwiper" :options="bannerSwiperOptions" v-if="pageData.bannerList && pageData.bannerList.length">
          <swiper-slide v-for="img in pageData.bannerList" :key="img.id">
            <base-img class="banner-img" :src="img.url"></base-img>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    <div class='page-body page-content flex-j-b'>
      <left-menu :activeKey='tab' :data='leftMenuData' title='走进青欣' @change='changeTab'></left-menu>
      <div class='honor-box' style='margin-left:32px;'>
          <div class='tab-title'>公司荣誉</div>
          <div class="flex-row-wrap" v-if='pageData.honorImgs.length' v-viewer>
            <div v-for="(item,index) in pageData.honorImgs" :key="item.id + index" class="honor-img">
              <base-img :src="item.url"></base-img>
              <a-popover>
                <template slot="content">
                  <p>{{item.title}}</p>
                </template>
                <div class='honor-img-name'>{{item.title}}</div>
              </a-popover>
            </div>
          </div>
          <div v-else style='margin-top:100px;'>
            <a-empty description='暂无数据'></a-empty>
          </div>
        </div>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/pageComponents/Nav'
import Footer from '@/components/pageComponents/Footer'
import LeftMenu from '@/components/pageComponents/LeftMenu'
import { design } from '@/api'
export default {
  name: 'CompanyHonor',
  components: {
    Nav,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      tab: '2',
      pageData: {
        honorImgs: [],
      },
      bannerSwiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        effect: 'fade',
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        loop: true,
      },
      leftMenuData: [
        {
          title: '集团介绍',
          key: '0',
        },
        {
          title: '集团文化',
          key: '1',
        },
        {
          title: '公司荣誉',
          key: '2',
        },
        {
          title: '资质证书',
          key: '3',
        },
      ],
    }
  },
  activated() {
    this.tab = '2'
  },
  async mounted() {
    await this.getDesignData()
  },
  methods: {
    async getDesignData() {
      const { data, msg, code } = await design.getDesign({ type: 'companyHonor' })
      if (code === '00000') {
        if (data) {
          this.designStorageId = data.designStorageId
          this.pageData = JSON.parse(data.designData)
        }
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    changeTab(e) {
      this.tab = e
      if (e === '0' && this.$route.path !== '/company_intro') {
        this.$router.push('/company_intro')
      }
      if (e === '1' && this.$route.path !== '/company_culture') {
        this.$router.push('/company_culture')
      }
      if (e === '2' && this.$route.path !== '/company_honor') {
        this.$router.push('/company_honor')
      }
      if (e === '3' && this.$route.path !== '/company_qualification') {
        this.$router.push('/company_qualification')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.body {
  background: #f1f1f1;
}
.page-content {
  padding: 24px 0;
}
.honor-box {
  min-height: 400px;
  width: 1008px;
  box-shadow: 0 4px 8px #ddd;
  border-radius: 8px;
  background: #fff;
  padding: 32px;
}
.tab-title {
  font-size: 32px;
  color: #38b352;
  margin-bottom: 48px;
  text-align: center;
}
.tab-desc {
  font-size: 16px;
  color: #2b2b2b;
  line-height: 28px;
  margin-bottom: 64px;
  text-align: left;
  text-indent: 2em;
  // padding: 0 100px;
}
.flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.flex-row-wrap .honor-img {
  width: calc((100% - 32px) / 3);
  object-fit: cover;
  display: block;
  margin-right: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
}
.flex-row-wrap .honor-img:nth-of-type(3n) {
  margin-right: 0;
}
.flex-row-wrap .honor-img:hover ::v-deep img {
  transform: scale(1.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}
.flex-row-wrap .honor-img ::v-deep .base-img {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row-wrap .honor-img ::v-deep img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: 1s;
}
.honor-img-name {
  font-size: 16px;
  line-height: 32px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
</style>